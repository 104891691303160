.accordion {
    width: 100%;
}

.accordion .accordionItem {
    background: linear-gradient(45deg, #6F3A8F, #F26B4B);
    border-radius: 20px;
    padding: 6px;
}

.accordion .accordionItem {
    width: 100%;
    margin-bottom: 10px;
}

.accordion button {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    border: none;
    background: white !important;
    height: 60px;
    width: 100%;
    cursor: pointer;
    color: #253f8e !important;
    font-size: 20px;
    font-weight: bold;
}

.accordion button span {
    transform: rotate(90deg);
}

.accordionItemContainer {
    background: white;
    border-radius: 14px;
    overflow: hidden;
}

.accordionItem dd {
    margin: 0;
}

.accordionItem li {
    color: #253f8e !important;
    font-size: 14px;
}