 header#mainHeader {
	 display: flex;
	 position: fixed;
	 width: 100vw;
	 padding: 10px;
	 background-color: #fff;
	 align-items: center;
	 justify-content: space-between;
	 top: 0;
	 left: 0;
	 right: 0;
	 z-index: 100;
}
 header#mainHeader .linkHome {
	 display: flex;
	 align-items: center;
}
 header#mainHeader .linkHome img {
	 height: 45px;
}
 header#mainHeader .linkHome span {
	 color: #253f8e;
	 font-weight: bold;
	 margin: 0 15px;
}
 header#mainHeader a {
	 text-decoration: none;
	 color: #253f8e;
}
 header#mainHeader a.framedLink {
	 border: 2px solid #253f8e;
	 padding: 5px 20px;
	 margin-right: 15px;
	 transition: all 0.2s;
	 text-align: center;
}
 header#mainHeader a.framedLink:hover {
	 background-color: #253f8e;
	 color: #fff;
}
 header#mainHeader a.framedLink + .framedLink {
	 margin-left: 25px;
}
 @media screen and (max-width: 1200px) {
	 header#mainHeader a.framedLink + .framedLink {
		 margin-left: 10px;
	}
}
 header#mainHeader a.framedLink.full {
	 background-color: #253f8e;
	 color: #fff;
}
 header#mainHeader a.framedLink.full:hover {
	 background-color: transparent;
	 color: #253f8e;
}
 header#mainHeader .menuBurger {
	 cursor: pointer;
	 display: none;
}
 header#mainHeader .menuBurger > div {
	 width: 30px;
	 height: 4px;
	 background-color: #253f8e;
	 border-radius: 10px;
	 transition: all 0.2s;
	 opacity: 1;
}
 header#mainHeader .menuBurger > div + div {
	 margin-top: 4px;
}
 header#mainHeader .menuBurger.open {
	 position: relative;
	 height: 25px;
	 width: 25px;
}
 header#mainHeader .menuBurger.open > div {
	 position: absolute;
}
 header#mainHeader .menuBurger.open > div:nth-child(1) {
	 top: 10px;
	 left: -2px;
	 transform: rotate(45deg);
}
 header#mainHeader .menuBurger.open > div:nth-child(2) {
	 opacity: 0;
}
 header#mainHeader .menuBurger.open > div:nth-child(3) {
	 bottom: 11px;
	 left: -2px;
	 transform: rotate(-45deg);
}
 header#mainHeader .mobileMenu {
	 display: none;
	 position: absolute;
	 top: 65px;
	 left: 0;
	 right: 0;
	 width: 100vw;
	 overflow: hidden;
	 transition: height 0.5s;
}

.closedMenu {
	height: 0;
}

 header#mainHeader .mobileMenu .menuContainer {
	 display: flex;
	 flex-direction: column;
	 align-items: center;
	 padding: 10px;
	 background-color: #fff;
}
 header#mainHeader .mobileMenu .menuContainer a {
	 font-size: 1.3rem;
	 display: block;
	 margin-bottom: 15px;
}
 header#mainHeader .mobileMenu .menuContainer .framedLink {
	 margin-right: 0;
	 margin-left: 0 !important;
}
 header#mainHeader .mobileMenu .menuContainer div:last-child {
	 margin-top: 20px;
}
 @media screen and (max-width: 1024px) {
	 header#mainHeader > *:not(.linkHome) {
		 display: none;
	}
	 header#mainHeader .menuBurger {
		 display: block;
	}
	 header#mainHeader .mobileMenu {
		 display: block;
	}
}
 