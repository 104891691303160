#mire{
    font-size:14px;
    min-width:400px;
    margin:0 30px 0 30px;
    padding:0 30px 0 30px;
}

#simple-dialog-title h2, #simple-dialog-title h6 {
    color: #28558a !important;
    text-align:center;
    font-size:25px;
}

/*
    
            button: {
                margin: 6,
                color: '#889DB6',
                backgroundColor:'#28558A',
            },
*/

#mire input[type="text"] {
    width: 100%;
    font-size:16px;
    background-color: rgba(169,178,190,0.2);
    color: rgba(40, 85, 138,1);
}


#hia_footer {
    background-image: url('images/logo HIA sprint.tiff');
    background-repeat: no-repeat;
    background-position-x:right 5px !important;
    background-size: contain;
    background-position: center;
    background-color: transparent;
    z-index: 10;
    position: relative;
    margin: 0;
    padding: 0;
    border: 0;
}