#base {
    display: flex;
    padding: 40px 80px;
    background-color: white;
    height: 100%;
    background-size: cover;
}

#base > div {
    flex: 1;
}

#base > div:not(:last-child) {
    margin-right: 100px;
}

#base .leftBox {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

#base .leftBox .baseTitle {
    font-family: "NewFrench";
    color: #253f8e;
    font-size: 30px;
    margin-bottom: 0;
}

#base .leftBox .baseTitle::first-letter {
    margin-left: -20px;
}

#base .leftBox .baseTitle span {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 50px;
    font-weight: bold;
}

#base .leftBox .baseSubTitle {
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-size: 30px;
    font-weight: bold;
    margin-top: 10px;
}

#base .centerBox {
    display: flex;
    justify-content: center;
    align-items: center;
}

#base .rightBox {
    position: relative;
}

#base .rightBox .blueLine {
    position: absolute;
    width: 30px;
    height: 10px;
    background: #253f8e;
}

@media screen and (max-width: 1280px) {
    #base > div:not(:last-child) {
        margin-right: unset;
    }

    #base .leftBox p {
        text-align: center;
    }

    #base .leftBox .baseTitle {
        font-size: 25px;
    }

    #base .centerBox .accordion {
        padding: 0 20px;
        max-width: 600px;
    }
}

@media screen and (max-width: 700px) {
    #base {
        padding: 40px 0;
    }
}